import {
  COUNTRIES,
  MIN_CHARS_SEARCH,
  getDayOfTheWeek,
  getMonths
} from './constants';
import moment, { isDate } from 'moment';
import { DateTime } from 'luxon';

const slugfy = require('slugify');

import { zonedTimeToUtc } from 'date-fns-tz';

export function sortArrayAlphabetically(arr = [], key = 'label') {
  return arr?.sort((a, b) => a[key].localeCompare(b[key])) || [];
}

export function getBaseURL() {
  const windowBasePath =
    typeof window !== 'undefined'
      ? window.location.origin.replace(/\/+$/, '')
      : '';

  const baseUrl =
    windowBasePath ||
    process.env.BASE_URL ||
    process.env.VERCEL_URL ||
    process.env.NEXT_PUBLIC_VERCEL_URL;

  const result = /^http/i.test(baseUrl) ? baseUrl : `https://${baseUrl}`;
  return result;
}

export const capitalize = (str = '') => {
  return str?.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeAllWords = (str) => {
  if (!str || str === 'BYOL') return str;
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
};

export const formatDate = (str) => {
  let d = new Date(str);

  const getDateFormatted =
    (d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) +
    '/' +
    (d.getDate() > 9 ? d.getDate() : '0' + d.getDate()) +
    '/' +
    d.getFullYear();

  return getDateFormatted;
};

export const formatDateUTC = (str) => {
  const date = new Date(str);

  var month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  var day = date.getUTCDate().toString().padStart(2, '0');
  var year = date.getUTCFullYear().toString();

  var getDateFormatted = month + '/' + day + '/' + year;

  return getDateFormatted;
};

export const slugify = (str) => {
  return slugfy(str, {
    lower: true,
    remove: /[*+~.,()'"!:@]/g
  });
};

export const generateErpId = async (dbClient) => {
  const min = 1;
  const max = 99999999;
  const rand = Math.floor(min + Math.random() * (max - min));
  const isThereUser = await dbClient.partner.findFirst({
    where: {
      partnerErpId: rand.toString()
    }
  });
  if (isThereUser) {
    generateErpId(dbClient);
  } else {
    return rand.toString();
  }
};

export const findContinent = (country) => {
  return COUNTRIES.find((e) => e.name.includes(country))?.continent;
};

export const diff = (obj1, obj2) => {
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
    return obj1;
  }

  var diffs = {};
  var key;

  var arraysMatch = function (arr1, arr2) {
    if (arr1.length !== arr2.length) return false;

    for (var i = 0; i < arr1.length; i++) {
      var type1 = Object.prototype.toString.call(arr1[i]);
      if (type1 === '[object Object]') {
        var objDiff = diff(arr1[i], arr2[i]);
        if (Object.keys(objDiff).length > 0) {
          return false;
        }
      } else if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  };

  var compare = function (item1, item2, key) {
    var type1 = Object.prototype.toString.call(item1);
    var type2 = Object.prototype.toString.call(item2);

    if (type2 === '[object Undefined]') {
      diffs[key] = null;
      return;
    }

    if (type1 !== type2) {
      diffs[key] = item1;
      return;
    }

    if (type1 === '[object Object]') {
      var objDiff = diff(item1, item2);
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff;
      }
      return;
    }

    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item1;
      }
      return;
    }

    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item1;
      }
    } else {
      if (item1 !== item2) {
        diffs[key] = item1;
      }
    }
  };

  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key);
    }
  }

  return diffs;
};

export const validateWebsite = (website) => {
  let regex =
    /^(http(s)?:\/\/)?(www\.)?([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}(\/[^\s]*)?$/i;

  const result = website.match(regex);
  const message = result ? '' : 'You have entered an invalid website!';

  return [result, message];
};

export const validateAddress = (address) => {
  const result = address.length >= 3;
  const message = result
    ? ''
    : 'The address should be at least 3 characters long!';

  return [result, message];
};

export function onlyLettersNumbersSpaces(str) {
  return /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/.test(str); //only numbers, letter, and spaces (no "ç", "`´", "~")
}

export function onlyLettersNumbersSpacesAndLettersWithAccentuation(str) {
  return /^[A-Za-z0-9\u00C0-\u017F\s]*$/.test(str);
}

// Validate emails
export const validateEmail = (email, getTranslation = null) => {
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~’\-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

  const result = email.match(regex);
  const message = result
    ? ''
    : getTranslation
    ? getTranslation('invalid_contact_email')
    : 'You have entered an invalid contact email address!';

  return [result, message];
};

// Validate linkedIn url
export const validateLinkedIn = (url, getTranslation = null) => {
  const regex =
    /^(https?:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/(in|company)\/[\w\-._~:/?#\[\]@!$&'()*+,;=%À-ÖØ-öø-ÿ]+\/?$/;
  const result = url.match(regex);
  const message = result
    ? ''
    : getTranslation
    ? getTranslation('invalid_linkedin_url')
    : 'You have entered an invalid LinkedIn URL!';

  return [result, message];
};

export const removeDuplicates = (array) => {
  array = array?.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.name === value.name)
  );
  return array;
};

export const arraysAreEqual = (ary1, ary2) => {
  return ary1?.join('') == ary2?.join('');
};

export const wasDismissed = (dismissArr, key) => {
  return dismissArr?.some((d) => d.includes(key));
};

export const arraysAreEqualByDiffs = (ary1, ary2, key = 'id') => {
  const diffs = ary1
    ?.filter(({ [key]: id1 }) => !ary2?.some(({ [key]: id2 }) => id2 === id1))
    ?.concat(
      ary2?.filter(
        ({ [key]: id1 }) => !ary1?.some(({ [key]: id2 }) => id2 === id1)
      )
    );

  return !diffs?.length > 0;
};

export const sortObject = (o) =>
  Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});

export const arraysOfObjectsAreEqual = (ary1 = [], ary2 = []) => {
  if (ary1?.length !== ary2?.length) return false;
  let areEqual = true;

  ary1?.map((element, key) => {
    if (
      JSON?.stringify(sortObject(element)) !==
      JSON?.stringify(sortObject(ary2[key]))
    )
      areEqual = false;
  });

  return areEqual;
};

export const getItemsOfObject = (idComponent) => {
  const object = [{ awards: ['position', 'title'] }];
  return object.find((e) => e[idComponent])?.[idComponent];
};

export const acceptOrUndoDeletion = (
  arrayToDelete,
  arrayToUndo,
  obj,
  partner
) => {
  if (arrayToDelete && arrayToDelete.length > 0) {
    for (let item of arrayToDelete) {
      partner.draft
        ? (partner.draft[obj] = partner.draft[obj]?.filter(
            (el) => el.key !== item?.split('.')[1]
          ))
        : (partner[obj] = partner[obj]?.filter((el) => el.key !== item));
    }
  }
  if (arrayToUndo && arrayToUndo.length > 0) {
    for (let item of arrayToUndo) {
      partner.draft[obj].find(
        (el) => el.key === item?.split('.')[1]
      ).deleted = true;
    }
  }
  return partner;
};

export const formatDateTime = (str) => {
  let d = new Date(str);
  const getDateFormatted =
    (d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) +
    '/' +
    (d.getDate() > 9 ? d.getDate() : '0' + d.getDate()) +
    '/' +
    d.getFullYear() +
    ' ' +
    (d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) +
    ':' +
    (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()) +
    ':' +
    (d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds());

  return getDateFormatted;
};
export const resizedImage = async (
  e,
  files,
  MAX_WIDTH = 800,
  MAX_HEIGHT = 800
) => {
  let img = document.createElement('img');
  img.src = e.target.result;
  await img.decode();

  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);

  let width = img.width;
  let height = img.height;

  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
  }
  canvas.width = width;
  canvas.height = height;
  let ctx2 = canvas.getContext('2d');
  ctx2.drawImage(img, 0, 0, width, height);

  return canvas.toDataURL(files[0].type);
};

export const csv_string_to_object = (csv_substring) => {
  let csv_rows = csv_substring.split('"\n"');
  let finalObject = [];
  let row_data = csv_rows.map((el) => el.split('","'));
  row_data = row_data.map((el) => {
    return el.map((eli) => eli.replace(/"/g, ''));
  });
  for (let j = 1; j < csv_rows.length; j++) {
    let obj = {};
    for (let i = 0; i < row_data[j].length; i++) {
      obj[row_data[0][i]] = row_data[j][i];
    }
    finalObject.push(obj);
  }
  return finalObject;
};

// Generate a color for the string
export const getStringColor = (string = '') => {
  const saturation = 40;
  const lightness = 70;

  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  return 'hsl(' + (hash % 360) + ', ' + saturation + '%, ' + lightness + '%)';
};

export const getThousandsConvertedAmount = (number) => {
  let separators = 0;
  let count = 0;
  let convertedNumber;
  let number_integer = number.toString().split('.')[0];
  let number_integer_decimal = number.toString().split('.')[1];
  for (var i = number_integer.length - 1; i >= 0; i--) {
    if (count === 3) {
      convertedNumber = ',' + convertedNumber;
      count = 0;
      separators++;
    }
    if (convertedNumber) {
      convertedNumber = number_integer.toString().charAt(i) + convertedNumber;
    } else {
      convertedNumber = number_integer.toString().charAt(i);
    }
    count++;
  }

  convertedNumber = number_integer_decimal
    ? convertedNumber + '.' + number_integer_decimal
    : convertedNumber;
  return convertedNumber;
};

/**
 *
 * @param {string} dateString eg: 12/25/2022
 * @param {int} years eg: 1
 * @returns boolean
 */
export const isDateOlderThanXYears = (dateString, years) => {
  const date = new Date(dateString);
  const today = new Date();
  const xYearsAgo = new Date(
    today.getFullYear() - years,
    today.getMonth(),
    today.getDate()
  );
  return date < xYearsAgo;
};

export const addMonths = (date, months) => {
  const a = new Date(date);

  let d = a.getUTCDate();
  a.setUTCMonth(a.getUTCMonth() + +months);

  if (a.getUTCDate() != d) {
    a.setUTCDate(0);
  }
  a.setUTCDate(a.getUTCDate() - 1);
  return a;
};

// Check if every term in the search has at least MIN_CHAR_SEARCH characters
export const checkMinSearchChars = (searchTerm) => {
  const sanitizedSearchTerm = searchTerm
    ?.replace(/[^a-zA-Z0-9\s;]/g, '')
    ?.trim();

  const searchTerms = sanitizedSearchTerm?.split(';');

  return searchTerms?.every((el) => {
    const term = el.trim();
    return !!term.length ? term.length >= MIN_CHARS_SEARCH : true;
  });
};

export function generateYearsBetween(startYear, endYear) {
  const endDate = endYear || new Date().getFullYear();
  let years = [];

  while (startYear <= endDate) {
    years.push(startYear);
    startYear++;
  }
  return years;
}

const parseFilters = (filters) => {
  let newFilters = {};

  Object.entries(filters).map(([key, value]) => {
    newFilters[key] = Array.isArray(value) ? [...value] : value;
  });

  return newFilters;
};

const getTabInSelectedLanguage = (tab, i18n) => {
  let tabSelected = '';
  let tabOriginalLang = '';

  const tabLanguages = [
    { en: 'Marketplace' },
    { 'en-GB': 'Marketplace' },
    { 'en-US': 'Marketplace' },
    { pt: 'Mercado', en: 'Marketplace' },
    { es: 'Mercado', en: 'Marketplace' },
    { cn: '市场', en: 'Marketplace' },
    { ja: '市場', en: 'Marketplace' },
    { en: 'Partner Finder' },
    { 'en-GB': 'Partner Finder' },
    { 'en-US': 'Partner Finder' },
    { pt: 'Localizador de parceiros', en: 'Partner Finder' },
    { es: 'Buscador de socios', en: 'Partner Finder' },
    { cn: '合作伙伴查找器', en: 'Partner Finder' },
    { ja: 'パートナー ファインダー', en: 'Partner Finder' },
    { en: 'Knights' },
    { pt: 'Cavaleiros', en: 'Knights' },
    { es: 'Caballeros', en: 'Knights' },
    { cn: '骑士团', en: 'Knights' },
    { ja: 'ナイツ', en: 'Knights' },
    { en: 'Events' },
    { pt: 'Eventos', en: 'Events' },
    { es: 'Eventos', en: 'Events' },
    { cn: '事件', en: 'Events' },
    { ja: 'イベント', en: 'Events' }
  ];

  //Change URL language to english
  tabLanguages.map((language) => {
    if (
      Object.values(language).indexOf(tab) > -1 &&
      tabOriginalLang.length === 0
    ) {
      tabOriginalLang = language.en;
      return;
    }
  });
  //English to selected language
  tabLanguages
    .filter(
      (e) =>
        e.en === tabOriginalLang ||
        e['en-GB'] === tabOriginalLang ||
        e['en-US'] === tabOriginalLang
    )
    .map((el) => {
      if (el[i18n.language] && tabSelected.length === 0) {
        tabSelected = el[i18n.language];
        return;
      }
    });

  return tabSelected;
};

const serializeToUrl = (filters) => {
  const filtersSerialized = encodeURIComponent(
    Object.keys(filters)
      .map((key) => key + '=' + filters[key])
      .join('&')
  );

  return filtersSerialized;
};

export const deSerializeFromUrl = (url, i18n, standalone = false) => {
  let filterName = '';
  let filtersObj = {};
  let currentTab = '';

  if (standalone) {
    Object.keys(url).map((key) =>
      key.split('&').map((e) =>
        e.split('=').map((param, index) => {
          if (index === 0) {
            filterName = param;
          } else {
            filtersObj[filterName] =
              filterName === 'complexSearch' ||
              filterName === 'complexSearchOffering' ||
              filterName === 'complexSearchKnight' ||
              filterName === 'complexSearchCSP' ||
              filterName === 'complexSearchEvent'
                ? param
                : filterName === 'starRating'
                ? param.split(',').join('')
                : param.includes(',')
                ? param.split(',')
                : [param];
            filterName = '';
          }
        })
      )
    );
  } else {
    Object.keys(url).map((link) =>
      link.split('|').map((key, index) => {
        if (index === 0) {
          currentTab = getTabInSelectedLanguage(key, i18n);
        } else {
          key.split('&').map((e) =>
            e.split('=').map((param, index) => {
              if (index === 0) {
                filterName = param;
              } else {
                filtersObj[filterName] =
                  filterName === 'complexSearch' ||
                  filterName === 'complexSearchOffering' ||
                  filterName === 'complexSearchKnight' ||
                  filterName === 'complexSearchCSP' ||
                  filterName === 'complexSearchEvent'
                    ? param
                    : filterName === 'starRating'
                    ? param.split(',').join('')
                    : param.includes(',')
                    ? param.split(',')
                    : [param];
                filterName = '';
              }
            })
          );
        }
      })
    );
  }

  return { currentTab, filtersObj };
};

export const addToRouter = ({
  filters,
  router,
  currentTab = '',
  validFilters
}) => {
  const tab = currentTab.length > 0 ? `${currentTab}|` : '';
  router.push(
    {
      query:
        Object.keys(filters)?.filter((el) => validFilters?.includes(el))
          .length > 0 && `${tab}${serializeToUrl(parseFilters(filters))}`
    },
    undefined,
    {
      shallow: true
    }
  );
};

export function isNumber(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

//Convert array of strings to string and add "and" instead of comma in the last value
export const convertArrayToString = (arr) => {
  if (arr.length === 0) {
    return '';
  } else if (arr.length === 1) {
    return arr[0];
  } else if (arr.length === 2) {
    return arr.join(' and ');
  } else if (arr.length === 3) {
    let lastString = arr.pop();
    return arr.join(', ') + ' and ' + lastString;
  } else {
    const slicedArray = arr.slice(0, 3);
    const otherElements = arr.length - slicedArray.length;
    return (
      slicedArray.join(', ') + ' and other(s) ' + otherElements + ' partner(s) '
    );
  }
};

export const validateNumberWords = (text = '', limit) => {
  const formatted = text?.trim();
  const words = formatted.split(' ');

  return words.length > limit;
};

export const validateNumberChars = (text = '', limit) =>
  text?.trim().length > limit;

export const isObject = (x) => {
  return typeof x === 'object' && !Array.isArray(x) && x !== null;
};

export const Trim = (str) => {
  if (typeof str !== 'string') return str;
  return str.trim();
};

export const padTo2Digits = (num) => {
  return String(num).padStart(2, '0');
};

//get hours and minutes
export const getHoursAndMinutes = (date = new Date()) => {
  return padTo2Digits(date.getHours()) + ':' + padTo2Digits(date.getMinutes());
};

export const generateArrayOfYears = (minYears, maxYear) => {
  let max = maxYear;
  let min = max - minYears;
  let years = [];

  for (let i = min; i <= max; i++) {
    years.push(i);
  }
  return years;
};

export const addTimeToDate = ({ date, time }) => {
  const dateNoTime = date.split('T')[0];
  const timeConverted = time?.includes('Z') ? time : `${time}:00.000Z`;
  return `${dateNoTime}T${timeConverted}`;
};

export const getTimeFromDate = ({ date, timezoneHoursOffset = null }) =>
  getNewDate({ date, timezoneHoursOffset })
    .split('T')[1]
    .split('.')[0]
    .slice(0, 5);

export const adjustDateByTimezoneOffset = (dateString, offsetHours) => {
  const date = new Date(dateString);
  const offsetMilliseconds = offsetHours * 60 * 60 * 1000;
  const adjustedDate = new Date(date.getTime() + offsetMilliseconds);
  const adjustedISOString = adjustedDate.toISOString();

  return adjustedISOString;
};

export const convertDateToFormat = (date, formatUSDate) => {
  const convertedDate = isDate(date) ? DateTime.fromJSDate(date).toISO() : date;
  const day = convertedDate?.split('T')[0]?.split('-')[2];
  const month = convertedDate?.split('T')[0]?.split('-')[1];
  const year = convertedDate?.split('T')[0]?.split('-')[0];
  const hours = convertedDate?.split('T')[1]?.split(':')[0];
  const minutes = convertedDate?.split('T')[1]?.split(':')[1];
  const seconds = convertedDate?.split('T')[1]?.split(':')[2]?.slice(0, 2);

  return formatUSDate
    ? moment(
        new Date(year, Number(month) - 1, day, hours, minutes, seconds)
      ).format('l')
    : new Date(year, Number(month) - 1, day, hours, minutes, seconds);
};

export const getNewDate = ({
  isUTC0 = false,
  date,
  returnDateObject = false,
  formatUSDate = false,
  timezoneHoursOffset = null,
  endOfDay = false,
  getCurrentDateAndTime = false,
  getISODateFromUsersTimezone = false,
  getDateObjectFromUsersTimezone = false,
  usersTimezoneLocation = '',
  removeTimezone = false
}) => {
  if (getISODateFromUsersTimezone) {
    if (isDate(date)) {
      return removeTimezone
        ? DateTime.fromJSDate(date, {
            zone: usersTimezoneLocation
          })
            .toISO()
            .split('T')[0] + 'T00:00:00.000Z'
        : DateTime.fromJSDate(date, {
            zone: usersTimezoneLocation
          }).toISO();
    } else {
      return removeTimezone
        ? DateTime.fromISO(date, {
            zone: usersTimezoneLocation
          })
            .toISO()
            .split('T')[0] + 'T00:00:00.000Z'
        : DateTime.fromISO(date, {
            zone: usersTimezoneLocation
          }).toISO();
    }
  }

  if (getDateObjectFromUsersTimezone) {
    if (isDate(date)) {
      return DateTime.fromJSDate(date, {
        zone: usersTimezoneLocation
      }).toJSDate();
    } else {
      return DateTime.fromISO(date, {
        zone: usersTimezoneLocation
      }).toJSDate();
    }
  }

  if (getCurrentDateAndTime) {
    return returnDateObject
      ? new Date()
      : DateTime.fromJSDate(new Date()).toISO();
  }
  //Date will be in ISO format by default ALL THE TIME
  if (isUTC0) {
    const now = !!date ? convertDateToFormat(date) : new Date();
    const convertedDate = `${now.getUTCFullYear()}-${String(
      now.getUTCMonth() + 1
    ).padStart(2, '0')}-${String(now.getUTCDate()).padStart(2, '0')}${
      endOfDay ? 'T23:59:59.000Z' : 'T00:00:00.000Z'
    }`;
    return returnDateObject
      ? convertDateToFormat(convertedDate, formatUSDate)
      : convertedDate;
  } else if (!!timezoneHoursOffset) {
    const convertedDate = adjustDateByTimezoneOffset(date, timezoneHoursOffset);
    return returnDateObject
      ? convertDateToFormat(convertedDate, formatUSDate)
      : convertedDate;
  } else {
    return !!date
      ? returnDateObject
        ? convertDateToFormat(date, formatUSDate)
        : new Date(date).toISOString()
      : returnDateObject
      ? new Date()
      : new Date().toISOString();
  }
};

export const getTimezone = ({ timezoneFrom, allTimezones }) => {
  const timezone = allTimezones.find(
    (tz) =>
      tz.abbreviation === timezoneFrom[0].split(' ')[1] &&
      tz.continentName === timezoneFrom[1].split(' (')[0] &&
      tz.countryName ===
        timezoneFrom[1]
          .split(' (')[1]
          .substring(0, timezoneFrom[1].split(' (')[1].length - 1)
  );

  return {
    timezoneName: timezone?.name,
    timezoneHoursOffset: timezone?.offset
  };
};
export const getEventTimezoneOffset = ({ eventTimezone, timezonesData }) => {
  return (
    timezonesData.filter((e) => e.label === eventTimezone)[0]
      ?.currentTimeOffsetInMinutes || 0
  );
};

export const getEventTimeDateInfo = ({ event, timezoneHoursOffset = 0 }) => {
  return {
    startDate: getNewDate({
      date: event?.startDate,
      timezoneHoursOffset: timezoneHoursOffset
    }),
    endDate: getNewDate({
      date: event?.endDate,
      timezoneHoursOffset: timezoneHoursOffset
    }),
    startCalendar: getNewDate({
      date: event?.startDate,
      timezoneHoursOffset: timezoneHoursOffset,
      returnDateObject: true
    }),
    endCalendar: getNewDate({
      date: event?.endDate,
      timezoneHoursOffset: timezoneHoursOffset,
      returnDateObject: true
    })
  };
};

export const convertTimezoneBasedOnDate = (
  timezone,
  date,
  timezonesData,
  short
) => {
  const eventTimezoneLocation = timezonesData.find((e) =>
    e.label.includes(timezone)
  )?.name;

  const convertedGMT = DateTime.fromISO(date, {
    zone: eventTimezoneLocation
  })
    .toString()
    .slice(23, 29);

  return short
    ? `GMT(${convertedGMT})`
    : `GMT(${convertedGMT}) ${timezone.slice(11)}`;
};

export const getHoursAndMinutesFromISODate = (date) => {
  const hours = new Date(date).toISOString().split('T')[1].split(':')[0];
  const minutes = new Date(date).toISOString().split('T')[1].split(':')[1];

  return `${hours}:${minutes}`;
};

export const getTimezonesData = (timezones, usersTimezone) => {
  let timezonesRemoveDup = [];
  timezones.forEach((element) => {
    if (
      timezonesRemoveDup.find(
        (elem) =>
          elem.abbreviation === element.abbreviation &&
          elem.continentName === element.continentName &&
          elem.countryName === element.countryName
      )
    ) {
      timezonesRemoveDup = timezonesRemoveDup.map((el) => {
        if (
          el.abbreviation === element.abbreviation &&
          el.continentName === element.continentName &&
          el.countryName === element.countryName
        ) {
          return {
            ...el,
            group: [...el.group, ...element.group]
          };
        } else {
          return el;
        }
      });
    } else {
      timezonesRemoveDup.push(element);
    }
  });

  const timezones_data = timezonesRemoveDup.map((tz) => ({
    label: `GMT(${tz.currentTimeFormat.split(' ')[0]}) ${tz.abbreviation} - ${
      tz.continentName
    } (${tz.countryName})`,
    value: `GMT(${tz.currentTimeFormat.split(' ')[0]}) ${tz.abbreviation} - ${
      tz.continentName
    } (${tz.countryName})`,
    currentTimeOffsetInMinutes: tz.currentTimeOffsetInMinutes,
    group: tz.group,
    usersTimezone: usersTimezone,
    name: tz.name
  }));

  return timezones_data;
};

export const getDayOfTheWeekFromDate = (date, t) =>
  getDayOfTheWeek(t)[date.getDay()].name.slice(0, 3);

export const convertDateNoTimezone = (d) => {
  return new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
};

export function getLastDayOfMonth({ date }) {
  return `${
    DateTime.fromJSDate(
      new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0, 23, 59, 59)
    )
      .toISO()
      .split('.')[0]
  }.000Z`;
}

export function getFirstDayOfMonth({ date }) {
  date.setUTCDate(1);
  return `${
    DateTime.fromJSDate(
      new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0,
        0
      )
    )
      .toISO()
      .split('.')[0]
  }.000Z`;
}

export function getFirstDayOfWeek({
  date,
  toShow = false,
  t = () => {
    return '';
  }
}) {
  const copiedDate = getNewDate({
    isUTC0: true,
    date,
    returnDateObject: true
  });
  const currentDayOfWeek = copiedDate.getUTCDay();
  const offset = (currentDayOfWeek - 0 + 7) % 7;
  copiedDate.setUTCDate(copiedDate.getUTCDate() - offset);
  const convertedDate = getNewDate({ isUTC0: true, date: copiedDate, t });

  if (toShow) {
    const day = convertedDate.split('T')[0].split('-')[2];
    const month = convertedDate.split('T')[0].split('-')[1];
    const monthString = t(getMonths(t).find((e) => e.number === month).short);
    return `${monthString} ${day}`;
  }

  return convertedDate;
}

export function getLastDayOfWeek({
  date,
  toShow = false,
  t = () => {
    return '';
  }
}) {
  const copiedDate = getNewDate({
    isUTC0: true,
    date,
    returnDateObject: true
  });

  const currentDayOfWeek = copiedDate.getUTCDay();
  const remainingDays = (6 - currentDayOfWeek + 7) % 7;
  copiedDate.setUTCDate(copiedDate.getUTCDate() + remainingDays);

  const convertedDate = getNewDate({
    isUTC0: true,
    date: copiedDate,
    endOfDay: true
  });

  if (toShow) {
    const day = convertedDate.split('T')[0].split('-')[2];
    const month = convertedDate.split('T')[0].split('-')[1];
    const year = convertedDate.split('T')[0].split('-')[0];
    const monthString = t(getMonths(t).find((e) => e.number === month).short);
    return `${monthString} ${day}, ${year}`;
  }

  return convertedDate;
}

export function getFirstDayOfYear(year) {
  // January is month 0 in JavaScript Date object
  return (
    DateTime.fromJSDate(new Date(year, 0, 1)).toISO().slice(0, 19) + '.000Z'
  );
}

export function getLastDayOfYear(year) {
  // December is month 11 in JavaScript Date object, and day 0 is the last day of the previous month
  return new Date(year, 11, 31, 23, 59, 59, 999).toISOString();
}

export function base64ToBlob(base64String, contentType = '') {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const timeAgo = (date, t, i18n) => {
  const currentDate = new Date();
  const inputDate = new Date(date);

  const timeDifferenceInSeconds = Math.floor((currentDate - inputDate) / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60
  };

  let counter;
  for (const [unit, seconds] of Object.entries(intervals)) {
    counter = Math.floor(timeDifferenceInSeconds / seconds);

    const unitTranslated = t(`${unit}`);

    if (counter > 0) {
      return counter === 1 && !['pt', 'es'].includes(i18n.language)
        ? t('units_ago', { unit: unitTranslated })
        : t(
            ['pt', 'es'].includes(i18n.language)
              ? `${i18n.language}_units_ago`
              : 'units_ago_counter',
            {
              unit:
                counter === 1 && i18n.language === 'pt'
                  ? unitTranslated
                  : unitTranslated
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, ''),
              sufixo:
                ['pt', 'es'].includes(i18n.language) && counter > 1 ? 'es' : '',
              counter
            }
          );
    }
  }

  return t('just_now');
};

// Validate Person Name
export const validatePersonName = (name) => {
  const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;

  const result = name.match(regex);
  const message = result ? '' : 'You have entered an invalid contact name!';

  return [result, message];
};

export const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const containsIgnoreCaseAndAccents = (original, search) => {
  const originalWithoutAccents = removeAccents(original.toLowerCase());
  const searchWithoutAccents = removeAccents(search.toLowerCase());
  return originalWithoutAccents.indexOf(searchWithoutAccents) >= 0;
};

export const exampleGallery = (
  <>
    <span style={{ wordBreak: 'break-word' }}>
      Examples of valid URLs:
      <br />
      <u>YouTube</u>: https://www.youtube.com/watch?v=ig_D0fwt-L8
      <br />
      <u>Vimeo</u>: https://vimeo.com/909471647
      <br />
      <u>Brightcove</u>:
      https://players.brightcove.net/618591172001/default_default/index.html?videoId=5850379697001
      <br />
      <u>Broadcom</u>:
      https://learn.broadcom.com/hubfs/Partner%20Logos_files%20for%20Expert%20Finder%20Tool/NUGASYS.mp4
      <br />
    </span>
  </>
);

export const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const isValidEmail = (email) => {
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~’\-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  return email.match(regex);
};

export const createDateWithEDT = (date) => {
  const timeZone = 'America/New_York';
  return zonedTimeToUtc(date, timeZone);
};

export const formatForSelect = (array) => {
  return array.map((item, index) => ({
    id: index,
    label: item,
    value: item
  }));
};

export const convertLabelToKey = (label) => {
  return label.toLowerCase().split(' ').join('_');
};

export const resourceTabs = ['VCF'];
